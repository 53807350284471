import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";
import './style.css';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
    },
    {
        id: 3,
        title: 'About',
        link: '/about',
    },
    {
        id: 6,
        title: 'Products',
        link: '/property',
    },
    {
        id: 88,
        title: 'Contact',
        link: '/contact',
    }
];

const MobileMenu = ({ mobmenuActive, setmobMenuState }) => {
    const [openId, setOpenId] = useState(0);
    const location = useLocation();
    const ClickHandler = () => {
        window.scrollTo(10, 0);
        setmobMenuState(false);
    };

    return (
        <div>
            <div className={`mobileMenu ${mobmenuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="close" onClick={() => setmobMenuState(!mobmenuActive)}>
                        <i className="ti-close"></i>
                    </div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => (
                        <ListItem
                            className={location.pathname === item.link ? 'active' : null}
                            key={mn}
                        >
                            {item.submenu ? (
                                <Fragment>
                                    <p
                                        onClick={() => setOpenId(item.id === openId ? 0 : item.id)}
                                    >
                                        {item.title}
                                        <i
                                            className={
                                                item.id === openId
                                                    ? 'fa fa-angle-up'
                                                    : 'fa fa-angle-down'
                                            }
                                        ></i>
                                    </p>
                                    <Collapse
                                        in={item.id === openId}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List className="subMenu">
                                            {item.submenu.map((submenu, i) => (
                                                <ListItem
                                                    key={i}
                                                    className={location.pathname === submenu.link ? 'active' : null}
                                                >
                                                    <Link
                                                        onClick={ClickHandler}
                                                        to={submenu.link}
                                                    >
                                                        {submenu.title}
                                                    </Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            ) : (
                                <Link
                                    onClick={ClickHandler}
                                    to={item.link}
                                >
                                    {item.title}
                                </Link>
                            )}
                        </ListItem>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MobileMenu;