import simg from '../images/jariThread/mission.png'
import simg2 from '../images/jariThread/vision.png'
import simg3 from '../images/jariThread/values.png'

const Services = [
    {
        Id: '1',
        sImg: simg,
        sTitle: 'MISSION',
        slug: 'mission',
        description: 'To Be Know For Highly Trusted & Affordable Embroidery Threads & Jari Provider In Surat',
        des2: 'Elementum aliquam byuerirra sagittis duis non quis.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '2',
        sImg: simg2,
        sTitle: 'VISION',
        slug: 'vision',
        description: 'To be the preferred choice for customers seeking the highest quality threads and jari',
        des2: 'Elementum aliquam byuerirra sagittis duis non quis.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '3',
        sImg: simg3,
        sTitle: 'CORE VALUE',
        slug: 'coreValue',
        description: 'Honest , Relationship , Transparancy, Trust , Commitment ',
        des2: 'Elementum aliquam byuerirra sagittis duis non quis.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    }
]
export default Services;