import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import ProjectPage from '../ProjectPage/ProjectPage';
import ContactPage from '../ContactPage/ContactPage';
const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          {/* <Route path="home2" element={<HomePage2 />} /> */}
          <Route path="about" element={<AboutPage />} />
          <Route path="property" element={<ProjectPage />} />
          {/* <Route path="project-single/:slug" element={<ProjectSinglePage />} /> */}
          {/* <Route path="services" element={<ServicePage />} /> */}
          {/* <Route path="service-single/:slug" element={<ServiceSinglePage />} /> */}
          {/* <Route path='blog' element={<BlogPage />} /> */}
          {/* <Route path='blog-single/:slug' element={<BlogDetails />} /> */}
          <Route path='contact' element={<ContactPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
