import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import { FaPhone, FaPhoneAlt } from 'react-icons/fa';

const Contactpage = () => {

    return (
        <div>
            <div className="mt-4 d-flex flex-wrap justify-content-center">
                <div className="text-white m-4" style={{ backgroundColor: '#CA9C6A', flex: '1 1 300px', maxWidth: '400px' }}>
                    <div>
                        <iframe
                            width="100%"
                            height="350"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=177%20OLD%20GIDC%20KATARGAM%20SURAT+(shree%20ganesh%20jari)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.gps.ie/">gps tracker sport</a>
                        </iframe>
                    </div>
                    <div className="text-center p-4">
                        <h3>
                            <i className="fa fa-map-marker"></i> Branch 1
                        </h3>
                        <h4>177 OLD GIDC KATARGAM, SURAT</h4>
                        <div className='text-black'>
                            <div className='d-flex justify-content-center gap-2'>
                                <BsWhatsapp className='fs-3' /> <h3> Order now via whatsapp</h3>
                            </div>
                            <a href="https://wa.me/7359436041"><h3 className='wp-link'> 73594 36041</h3></a>
                        </div>
                    </div>
                </div>
                <div className="text-white m-4" style={{ backgroundColor: '#CA9C6A', flex: '1 1 300px', maxWidth: '400px' }}>
                    <div>
                        <iframe
                            width="100%"
                            height="350"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=350&amp;hl=en&amp;q=shree%20ganesh%20trading%20,10%20sai%20embro-1,amroli-sayan%20road%20,%20%20surat+(shree%20ganesh%20jari)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.gps.ie/">gps tracker sport</a>
                        </iframe>
                    </div>
                    <div className="text-center p-4">
                        <h3>
                            <i className="fa fa-map-marker"></i> Branch 2
                        </h3>
                        <h4>10 SAI AMBRO-1 AMROLI-SAYAN ROAD, SURAT</h4>
                        <div className='text-black'>
                            <div className='d-flex justify-content-center gap-2'>
                                <BsWhatsapp className='fs-3' /> <h3> Order now via whatsapp</h3>
                            </div>
                            <a href="https://wa.me/9824718038"  ><h3 className='wp-link'> 98247 18038 </h3></a>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default Contactpage;
