import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import Logo1 from '../../images/logo1.jpg'

const Header = () => {
    const [mobmenuActive, setmobMenuState] = useState(false);

    const location = useLocation(); // Get the current location

    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <header className="main-header">
            <div className="header-top">
                <div className="container">
                    <div className="top-inner">
                        <div className="logo-outer">
                            <div className="logo">
                                <Link onClick={ClickHandler} to="/">
                                    <img src={Logo1} alt="Logo" />
                                </Link>
                            </div>
                        </div>

                        <ul className="header-info">
                            <li>
                                <i className="fa fa-phone"></i>
                                <h6>
                                    <span>Call Anytime:</span>
                                    73594 36041 (GIDC)
                                    <span className='text-black'>98247 18038 (SAI AMBRO)</span>
                                </h6>
                            </li>
                        </ul>
                        <div className="navbar-header clearfix d-lg-none">
                            <button
                                type="button"
                                className="navbar-toggle"
                                onClick={() => setmobMenuState(!mobmenuActive)}
                            >
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-upper">
                <div className="container clearfix">
                    <div className="header-inner d-flex justify-content-center">
                        <div className="nav-outer clearfix">
                            <nav className="main-menu navbar-expand-lg">
                                <MobileMenu setmobMenuState={setmobMenuState} mobmenuActive={mobmenuActive} />
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li className={location.pathname === '/' ? 'active' : ''}>
                                            <Link onClick={ClickHandler} to="/">Home</Link>
                                        </li>
                                        <li className={location.pathname === '/about' ? 'active' : ''}>
                                            <Link onClick={ClickHandler} to="/about">About</Link>
                                        </li>
                                        <li className={location.pathname === '/property' ? 'active' : ''}>
                                            <Link onClick={ClickHandler} to="/property">Products</Link>
                                        </li>
                                        <li className={location.pathname === '/contact' ? 'active' : ''}>
                                            <Link onClick={ClickHandler} to="/contact">Contact us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;