import React from 'react'
import CountUp from 'react-countup';

const FunFact = (props) => {

    const funFact = [
        {
            title: '10',
            count: '10',
            subTitle: 'team',
            sts: 'members',
        },
        {
            title: '500+',
            count: '500',
            subTitle: 'happy',
            sts: 'customers',
        },
        {
            title: '40+',
            count: '40',
            subTitle: "company's",
            sts: 'products',
        },
        // {
        //     title: '40',
        //     subTitle: 'Team',
        //     sts: 'Members',
        // },


    ]

    return (

        <section className={`success-section ${props.fClass}`}>
            <div className="container">
                <div className="row d-flex justify-content-between">
                    {funFact.map((funfact, fitem) => (
                        <div className="col-xl-3 col-md-6 " key={fitem}>
                            <div className="success-box d-flex justify-content-center">
                                <span className="count-text" data-stop={funfact.title}><CountUp end={funfact.count} enableScrollSpy /></span>
                                <div className="success-content ">
                                    <h3>{funfact.subTitle}</h3>
                                    <h6>{funfact.sts}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>
    )
}

export default FunFact;