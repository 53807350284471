import pro1 from '../images/jariThread/pro1.png'
import pro2 from '../images/jariThread/pro2.jpeg'
import pro3 from '../images/jariThread/pro3.png'
import pro4 from '../images/jariThread/pro4.jpg'
import pro5 from '../images/jariThread/pro5.jpg'
import pro6 from '../images/jariThread/pro6.jpg'
import pro7 from '../images/jariThread/pro7.jpg'
import pro8 from '../images/jariThread/pro8.jpg'
import pro9 from '../images/jariThread/pro9.jpg'

const Projects = [
    {
        Id: '1',
        pTitle: 'Threads',
        slug: 'Threads',
        pImg: pro1,
        des: 'The essential building blocks of diverse textile artistry',
        width: '120',
    },
    {
        Id: '2',
        pTitle: 'Jari',
        slug: 'Jari',
        pImg: pro2,
        des: 'Elevating fabrics with shimmering elegance',
        width: '130',
    },
    {
        Id: '3',
        pTitle: 'Nylon Tar',
        slug: 'NylonTar',
        pImg: pro3,
        des: 'Durable and versatile, protecting what matters',
        width: '140',
    },
    {
        Id: '4',
        pTitle: 'german',
        slug: 'german',
        pImg: pro4,
        width: '120',
    },
    {
        Id: '5',
        pTitle: 'polyster',
        slug: 'polyster',
        pImg: pro5,
        width: '130',
    },
    {
        Id: '6',
        pTitle: 'viscose',
        slug: 'viscose',
        pImg: pro6,
        width: '140',
    },
    {
        Id: '7',
        pTitle: 'bobin dhaga',
        slug: 'bobin-dhaga',
        pImg: 'https://res.cloudinary.com/dchb1czwp/image/upload/v1732616780/bobin_buqq8n.jpg',
        width: '120',
    },
    {
        Id: '8',
        pTitle: 'coding dori',
        slug: 'coding-dori',
        pImg: pro8,
        width: '130',
    },
    {
        Id: '9',
        pTitle: 'badla jari',
        slug: 'badla jari',
        pImg: pro9,
        width: '140',
    },

]

export default Projects;